import React, { useState, useEffect } from 'react';
import Masthead from '../components/masthead';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Recaptcha from 'react-google-recaptcha';

const StyledContact = styled.div`
	.contact-row {
		display: flex;
		.form {
			padding: 3rem 2rem 3rem ${(p) => p.theme.gutter};
			background-color: #fff;
			width: 60%;
			.contact-holder {
				text-align: left;
				display: inline-block;
				width: 100%;

				form {
					display: flex;
					align-items: flex-start;
					flex-direction: column;
				}

				h1 {
					color: ${(p) => p.theme.color.dark};
					font-size: 2rem;
					font-weight: 700;
					margin-bottom: 1rem;
				}
				p {
					margin-bottom: 1rem;
					color: ${(p) => p.theme.color.dark};
				}
				input {
					margin-bottom: 1rem;
					line-height: 1.25rem;
					border: 1px solid ${(p) => p.theme.color.grey};
					width: 100%;
					color: ${(p) => p.theme.color.dark};
					padding: 0.6rem 1rem;
				}
				label {
					position: absolute !important;
					clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
					clip: rect(1px, 1px, 1px, 1px);
					padding: 0 !important;
					border: 0 !important;
					height: 1px !important;
					width: 1px !important;
					overflow: hidden;
				}
				textarea {
					border: none;
					resize: none;
					margin-bottom: 1rem;
					border: 1px solid ${(p) => p.theme.color.grey};
					height: 150px;
					width: 100%;
					color: ${(p) => p.theme.color.dark};
					padding: 0.6rem 1rem;
				}
				button {
					background-color: ${(p) => p.theme.color.dark};
				}
			}
			.thank-you {
				display: flex;
				justify-content: center;
				align-items: center;
				h2 {
					font-size: 3rem;
					color: ${(p) => p.theme.color.dark};
					margin-bottom: 1rem;
				}
				p {
					font-size: 2rem;
					line-height: 2.5rem;
					color: ${(p) => p.theme.color.dark};
				}
			}
		}
		.contact {
			background-color: ${(p) => p.theme.color.blue};
			padding: 4rem ${(p) => p.theme.gutter} 4rem 2rem;
			font-family: ${(p) => p.theme.fonts.title};
			width: 40%;
			color: #fff;
			.text-container {
				h2 {
					color: #fff;
					font-weight: 700;
				}
				hr {
					margin: 1.5rem 0 0.5rem;
				}
				h3 {
					font-size: 1.5rem;
					line-height: 1.3;
				}
				.phone {
					font-size: 1.3rem;
					line-height: 2rem;
				}
				.location {
					margin-bottom: 1rem;
					a {
						color: #fff;
						&.address {
							display: block;
							font-size: 1.2rem;
							line-height: 1.6rem;
						}
					}
				}
				.toll-free {
					display: inline-block;
					margin-top: 0.5em;
				}
			}
		}
	}

	.map {
		height: 18rem;
		background-image: url(${(p) => p.map});
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}
	.claims {
		background-color: ${(p) => p.theme.color.orange};
		padding: 2rem ${(p) => p.theme.gutter};
		h2 {
			text-transform: uppercase;
			font-size: 1.3rem;
			line-height: 2rem;
			font-weight: 700;
		}
		a {
			color: #fff;
			position: relative;
			&::after {
				content: '';
				background-color: #fff;
				transition: 0.3s;
				width: 100%;
				height: 1px;
				position: absolute;
				bottom: 0;
				right: 0;
				opacity: 0;
			}
			&:hover::after {
				opacity: 1;
			}
		}
	}
	@media all and (min-width: 1600px) {
		.contact-row {
			.form {
				padding: 3rem ${(p) => p.theme.gutterXWide};
				.contact-holder {
					h1 {
						font-size: 2.5rem;
					}
					p {
						width: 80%;
					}
				}
				.thank-you {
					h2 {
						font-size: 3.5rem;
					}
					p {
						font-size: 2.5rem;
					}
				}
			}
			.contact {
				.phone {
					font-size: 2rem;
				}
				a {
					&.address {
						font-size: 1.5rem;
						line-height: 2rem;
					}
				}
			}
		}
		.map {
			height: 25rem;
		}
		.claims {
			padding: 4rem ${(p) => p.theme.gutterXWide};
			h2 {
				font-size: 1.8rem;
				margin-bottom: 0.5rem;
			}
			.claims-container .claim {
				p {
					font-size: 1.8rem;
				}
				a {
					font-size: 2rem;
				}
			}
		}
	}
	@media all and (max-width: 868px) {
		.contact-row {
			flex-wrap: wrap;
			.form {
				width: 100%;
				padding: 3rem ${(p) => p.theme.gutter};
			}
			.contact {
				width: 100%;
				padding: 4rem ${(p) => p.theme.gutter};
			}
		}
	}
	@media all and (max-width: 768px) {
		.claims .claims-container .claim {
			width: 46%;
			margin-right: 8%;
			&:nth-child(3n) {
				margin-right: 8%;
			}
			&:nth-child(2n) {
				margin-right: 0;
			}
		}
	}
	@media all and (max-width: 500px) {
		.contact-row {
			.form {
				padding: 2rem ${(p) => p.theme.gutterMobile} 3rem;
			}
			.contact {
				padding: 2rem ${(p) => p.theme.gutterMobile} 1rem;
			}
		}
		.claims {
			padding: 2rem ${(p) => p.theme.gutterMobile};
			.claims-container .claim {
				width: 100%;
				margin-right: 0 !important;
			}
		}
	}
`;

const ContactTemplate = ({ data }) => {
	const [thanks, setThanks] = useState(false);
	const [quoteSource, setQuoteSource] = useState('Organic Click');
	const [height, setHeight] = useState(572);
	const [captchaVal, setCaptchaVal] = useState('');
	const [capErr, setCapErr] = useState(false);

	useEffect(() => {
		setThanks(window.location.search === '?thanks=true');

		if (window.location.search.indexOf('page') > -1)
			setQuoteSource(window.location.search.replace('?page=', ''));

		const handleResize = () => {
			if (window.innerWidth >= 1600) setHeight(642);
			if (window.innerWidth < 452) setHeight(596);
			if (window.innerWidth < 532 && window.innerWidth > 500) setHeight(596);
		};
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});

	const handleSubmit = (e) => {
		if (!captchaVal) {
			setCapErr(true);
			e.preventDefault();
		}
	};

	const { mast, form, contact, claims } = data;

	return (
		<>
			<Masthead image={mast.img} title={mast.title} text={mast.text} />

			<StyledContact map={claims.map}>
				<div className="contact-row">
					<div className="form" id="form">
						{!thanks && (
							<div className="contact-holder">
								<h1>{form.title}</h1>
								<hr align="left" />
								<p>{form.text}</p>
								<form
									netlify="true"
									data-netlify="true"
									data-netlify-honeypot="bot-field"
									data-netlify-recaptcha="true"
									name="Contact Form"
									action="/contact/?thanks=true#form"
									method="POST"
									onSubmit={handleSubmit}
								>
									<input type="hidden" name="form-name" value="Contact Form" />
									<input type="hidden" name="bot-field" />
									<input
										type="text"
										name="Name"
										id="Name"
										placeholder="FULL NAME"
										required
									/>
									<label htmlFor="Name">Name:</label>

									<input
										type="text"
										name="Email"
										id="Email"
										placeholder="EMAIL"
										required
									/>
									<label htmlFor="Email">Email:</label>

									<input
										type="text"
										name="Phone"
										id="Phone"
										placeholder="PHONE"
										required
									/>
									<label htmlFor="Phone">Phone:</label>

									<textarea
										name="Message"
										id="Message"
										placeholder="MESSAGE"
										rows="8"
									/>
									<label htmlFor="Message">Message:</label>
									<input
										type="hidden"
										name="Quote Source Page:"
										value={quoteSource}
									/>
									<div className="columns" style={{ width: '100%' }}>
										<div className="column">
											<Recaptcha
												sitekey={'6Lfuy70UAAAAAH4tIioay9doH-jalamEmyJXnQ6m'}
												onChange={(val) => {
													setCaptchaVal(val);
												}}
											/>
											<p
												style={{
													display: capErr ? 'block' : 'none',
													color: 'red',
													marginTop: '1rem',
												}}
											>
												Please confirm you are not a robot.
											</p>
										</div>
										<div className="column has-text-right has-text-left-mobile">
											<button type="submit" className="ec-button">
												Submit form
											</button>
										</div>
									</div>
								</form>
							</div>
						)}
						{thanks && (
							<div className="thank-you" style={{ height: `${height}px` }}>
								<div className="thank-you-holder">
									<h2>Thank you!</h2>
									<p>Your contact form has been submitted.</p>
								</div>
							</div>
						)}
					</div>
					<div className="contact">
						<div className="text-container">
							<h2>{contact.title}</h2>
							<hr align="left" />
							{contact.locations.map((x, i) => (
								<div className="location" key={i}>
									<h3>{x.locationName}</h3>
									<a
										className="address"
										target="_blank"
										rel="noopener noreferrer"
										href={x.address.link}
									>
										<p>{x.address.text1}</p>
										<p>{x.address.text2}</p>
										<p>{x.address.text3}</p>
									</a>
									<p className="phone white-link">
										Phone: <a href={x.phone.link}>{x.phone.text}</a>
									</p>
									{x.fax && (
										<p className="phone white-link">
											Fax: <a href={x.fax.link}>{x.fax.text}</a>
										</p>
									)}
									{x.tollFree && (
										<p className="phone white-link">
											Toll Free: <a href={x.tollFree.link}>{x.tollFree.text}</a>
										</p>
									)}
								</div>
							))}
							<h2>{contact.claims.title}</h2>
							<hr align="left" />
							<h3>{contact.claims.text}</h3>
							<a
								className="phone white-link toll-free"
								href={contact.claims.tollFree.link}
							>
								{contact.claims.tollFree.text}
							</a>
						</div>
					</div>
				</div>
				<a
					href={contact.locations[0].address.link}
					target="_blank"
					rel="noopener noreferrer"
				>
					<div className="map" />
				</a>
				<div className="claims">
					<h2>{claims.title}</h2>
					<Link to={claims.cta.link}>{claims.cta.text}</Link>
				</div>
			</StyledContact>
		</>
	);
};

export default ContactTemplate;
