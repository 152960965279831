import React from 'react';
import Layout from '../components/layout';
import ContactTemplate from '../templates/contact-template';
import SEO from '../components/seo';
import { graphql } from 'gatsby';

const Contact = ({ data }) => {
	const content = data.allMarkdownRemark.edges[0].node.frontmatter;
	return (
		<Layout page="Contact">
			<SEO
				title="Contact"
				description={
					data.allMarkdownRemark.edges[0].node.frontmatter.description
				}
			/>
			<ContactTemplate data={content} />
		</Layout>
	);
};

export default Contact;

export const query = graphql`
	{
		allMarkdownRemark(filter: { frontmatter: { slug: { eq: "contact" } } }) {
			edges {
				node {
					frontmatter {
						description
						mast {
							title
							text
							img
						}
						form {
							title
							text
						}
						contact {
							title
							locations {
								locationName
								address {
									text1
									text2
									text3
									link
								}
								phone {
									text
									link
								}
								fax {
									text
									link
								}
								tollFree {
									text
									link
								}
							}
							claims {
								title
								text
								tollFree {
									text
									link
								}
							}
						}
						claims {
							title
							map
							cta {
								text
								link
							}
						}
					}
				}
			}
		}
	}
`;
